
import { ref, defineComponent } from "vue";
import Disciplina from "@/interfaces/Disciplina";
import Assunto from "@/interfaces/Assunto";
import Aula from "@/interfaces/Aula";
import AnexoColumn from "@/util/table-columns/AnexoColumn";
import BaseService from "@/services/admin/BaseService";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
import AnexoUpload from "@/components/admin/AnexoUpload.vue";

export default defineComponent({
  components: { AnexoUpload },
  setup() {
    const $q = useQuasar();
    const url = ref<string>(process.env.VUE_APP_STORAGE_BASE);
    const urlApi = ref<string>(process.env.VUE_APP_API_BASE);
    const show = ref(false);
    const showAssunto = ref(false);
    const showAula = ref(false);
    const loading = ref<boolean>(false);
    const indexAssuntoEsc = ref<number>();
    const indexAulaEsc = ref<number>();
    const columns = ref(AnexoColumn);
    const disciplina = ref<Disciplina>();
    const assunto = ref<Assunto>();
    const aula = ref<Aula>();
    const modalAulaUpload = ref();
    const modalAssuntoUpload = ref();
    const descricao = ref<string>("");

    const open = (data: Disciplina): void => {
      disciplina.value = JSON.parse(JSON.stringify(data));
      show.value = true;
    };

    const openAssunto = (data: Assunto): void => {
      assunto.value = data;
      showAssunto.value = true;
    };

    const openAula = (data: Aula): void => {
      aula.value = data;
      showAula.value = true;
    };

    const openModalAulaUpload = (): void => {
      if (aula.value) {
        modalAulaUpload.value.open(aula.value.id, descricao.value);
      }
    };

    const openModalAssuntoUpload = (): void => {
      if (assunto.value) {
        modalAssuntoUpload.value.open(assunto.value.id, descricao.value);
      }
    };

    const excluirAnexoAula = (id: string, aulaX: Aula): void => {
      loading.value = true;
      BaseService.delete("anexos-aula", id)
        .then(() => {
          showNotify({
            type: "positive",
            message: "Anexo excluído com sucesso",
          });
          let index = aulaX.anexos.findIndex((anexo) => anexo.id == id);
          aulaX.anexos.splice(index, 1);
          if (aula.value) {
            aula.value.anexos.splice(index, 1);
          }
        })
        .catch((err) => {
          console.error(err.response.data.message);
          showNotify({
            type: "negative",
            message: err.response.data.message,
          });
        })
        .finally(() => (loading.value = false));
    };

    const excluirAnexoAssunto = (id: string, assuntoX: Assunto): void => {
      loading.value = true;
      BaseService.delete("anexos-assunto", id)
        .then(() => {
          showNotify({
            type: "positive",
            message: "Anexo excluído com sucesso",
          });
          let index = assuntoX.anexos.findIndex((anexo) => anexo.id == id);
          assuntoX.anexos.splice(index, 1);
          if (assunto.value) {
            assunto.value.anexos.splice(index, 1);
          }
        })
        .catch((err) => {
          console.error(err.response.data.message);
          showNotify({
            type: "negative",
            message: err.response.data.message,
          });
        })
        .finally(() => (loading.value = false));
    };

    const addAnexo = (anexo: any) => {
      console.log(anexo);
      if (anexo.aula != null) {
        aula.value?.anexos.push(anexo);
      } else {
        assunto.value?.anexos.push(anexo);
      }
      descricao.value = "";
    };

    const formatData = (data: string): string => {
      return `${data.substr(8, 2)}/${data.substr(5, 2)}/${data.substr(0, 4)}`;
    };

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    return {
      url,
      urlApi,
      show,
      showAssunto,
      open,
      openAssunto,
      showAula,
      openAula,
      disciplina,
      indexAulaEsc,
      indexAssuntoEsc,
      loading,
      assunto,
      aula,
      columns,
      formatData,
      excluirAnexoAula,
      excluirAnexoAssunto,
      showNotify,
      modalAulaUpload,
      openModalAulaUpload,
      descricao,
      addAnexo,
      modalAssuntoUpload,
      openModalAssuntoUpload,
    };
  },
});
