
import { ref, defineComponent } from "vue";
import useQuasar from 'quasar/src/composables/use-quasar.js';;

export default defineComponent({
  props: {
    url: { default: "" },
  },
  setup(props, { emit }) {
    const id = ref<string>();
    const descricao = ref<string>();
    const $q = useQuasar();
    const show = ref<boolean>(false);
    const token = ref<string>();

    const open = (dataId: string, dataDescricao: string): void => {
      if (localStorage.getItem("token")) {
        id.value = dataId;
        descricao.value = dataDescricao;
        token.value = "Bearer " + localStorage.getItem("token")?.toString();
        show.value = true;
      }
    };

    const onRejected = (rejectedEntries: any): void => {
      $q.notify({
        type: "negative",
        message: `${rejectedEntries.length} file(s) did not pass validation constraints`,
      });
    };

    const afterSubmit = (info: any): void => {
      let response = JSON.parse(info.xhr.response);
      emit("update", response);
      show.value = false;
      showNotify({
        type: "positive",
        message: "Anexo salvo com sucesso",
      });
    };

    const afterFailed = (): void => {
      showNotify({
        type: "negative",
        message: "Erro ao salvar anexo",
      });
    };

    const showNotify = (opts: any): void => {
      $q.notify(opts);
    };

    return {
      afterSubmit,
      afterFailed,
      onRejected,
      show,
      open,
      id,
      descricao,
      token,
      showNotify,
    };
  },
});
