import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_uploader = _resolveComponent("q-uploader")!
  const _component_q_dialog = _resolveComponent("q-dialog")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    modelValue: _ctx.show,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.show) = $event))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_uploader, {
        label: "Enviar Anexo",
        accept: "*",
        width: "400px",
        "field-name": "anexo",
        onUploaded: _ctx.afterSubmit,
        onFailed: _ctx.afterFailed,
        onRejected: _ctx.onRejected,
        url: `${_ctx.url}${_ctx.id}`,
        headers: [
        {
          name: 'Authorization',
          value: _ctx.token,
        },
      ],
        "form-fields": [
        {
          name: 'descricao',
          value: _ctx.descricao,
        },
      ]
      }, null, 8, ["onUploaded", "onFailed", "onRejected", "url", "headers", "form-fields"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}